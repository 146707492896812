import React from 'react'
import Header from '../widgets/Header'
import Footer from '../widgets/Footer'
function teaminfo() {
  return (
    <div className='font-noto-sans-sc'>
      <Header />
       {/* <div className="relative w-full h-[732px] overflow-hidden mt-[6.5rem]">
        <video
          className="absolute top-0 left-0 w-full h-full object-cover"
          autoPlay
          loop
          muted
          playsInline
        >
          <source src="团队简介.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center ">
          <div className="text-white  font-noto-sans-sc ">
            <h1 className='font-noto font-regular tracking-[1rem] text-white text-[4.98rem] '>关于我们</h1>
            <p className="font-noto font-regular tracking-[0.2rem]  mt-[25px] text-[2.6rem]">About us</p>
          </div>
        </div>
      </div> */}
      <div className="relative w-full h-[732px] overflow-hidden mt-[6.5rem] ">
        <video
          className="absolute top-0 left-0 w-full h-full object-cover"
          autoPlay
          loop
          muted
          playsInline
        >
          <source src="team.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center ">
          <div className="text-white  font-noto-sans-sc leading-none">
            <h1 className='font-noto font-regular tracking-[0.14rem] text-white text-[3.75rem]  '>关于我们</h1>
            <p className="font-noto font-regular tracking-[0.14rem]  mt-[40px] text-[1.84rem] ">About us</p>
          </div>
        </div>
      </div>    
      {/* <div className='text-left h-[52.187rem] px-[20rem] py-[7.625rem]'>
      <p className='text-[3.312rem] mb-[1.375rem] font-noto font-bold '>杭州慕皓</p>
      <p className='text-[2rem]'>杭州慕皓成立于2022年，是一家聚焦于等离子技术的科技型初创公司。团队成员拥有深厚的等离子技术的底蕴及化工领域的经验，用绿色能源结合等离子方法，提供各种无二氧化碳排放的新材料及工艺技术。
      </p>
      </div> */}
      <div className="flex justify-center h-[33.625rem] pt-[175px]  font-noto font-regular max-w-full mx-auto">
        <div className='text-left max-w-[1298px]'>
          <p className='text-[3.29rem] mb-[1.375rem] font-noto font-bold '>杭州慕皓</p>
          <p className='text-[1.5rem] font-noto font-thin'>杭州慕皓成立于2022年，是一家聚焦于等离子技术的科技型初创公司。团队成员拥有深厚的等离子技术的底蕴及化工领域的经验，用绿色能源结合等离子方法，提供各种无二氧化碳排放的新材料及工艺技术。
          </p>
        </div>
      </div>
      <Footer/>
    </div>


  )
}

export default teaminfo
