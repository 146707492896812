import React from 'react'
import Header from '../widgets/Header'
import Footer from '../widgets/Footer'
// import { css as css1 } from '../assets/NotoSansCJKsc-Thin.otf?subsets';
function news() {
  return (
    <div className=''>
      <Header />
      <div className="relative w-full h-[732px] overflow-hidden mt-[4.5rem] ">
        <img className='w-full object-cover h-full' src="https://www.freeimg.cn/i/2024/07/17/66976befa6605.png">
        </img>
        {/* <img className='w-full object-cover h-full' src="news2.png">
        </img> */}
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center ">
          <div className="text-white  font-noto-sans-sc leading-none">
            <h1 className='font-noto font-regular tracking-[0.14rem] text-white text-[3.75rem]  '>新闻动态</h1>
            <p className="font-noto font-regular tracking-[0.14rem]  mt-[40px] text-[1.84rem] ">News</p>
          </div>
        </div>
      </div> 
      
      <div className='flex flex-col py-[16rem] px-[11rem] h-auto mb-[276px]'>
      <div className='flex bg-[#F7F7F7] h-[441px] py-[2rem] px-[1.68rem] mx-auto' style={{ width: '100%', maxWidth: '1078px' }}>
        <div className="w-[389px] h-[389px] ">
          <img src="news1.png" className="w-full h-full object-cover" />
        </div>
        <div className='text-left ml-[4.3rem] w-[521px] flex flex-col justify-between h-full'>
          <p className='text-left pb-[2rem] text-[2.25rem] font-noto font-bold '>恭喜！第一阶段成功！</p>
          <div className="w-full h-[0.1rem] mb-[1rem] bg-[#231815] border-t"></div>
          <p className='h-[22rem] text-[1.37rem] font-noto font-thin'>2024年7月xx日，我们的实验室开发阶段将告一段落，并立即进入开发的下一阶段。在实验室阶段，我们完美地达到了预期的结果，为我们的研发团队喝彩！</p>
          <p className='text-[1.37rem] text-right font-noto font-thin'>MORE{'>'}</p>
        </div>
      </div>
      {/* <div className='flex mx-auto' style={{ width: '100%', maxWidth: '1078px' }}>
        <div className='w-[516px] h-[441px] bg-[#F7F7F7] mt-[4rem] px-[5rem] py-[4rem]'>
          <div className='flex mb-[1rem]'>
            <img src="news1.png" className='w-[103px] h-[103px]' />
            <p className='max-w-xs overflow-hidden whitespace-nowrap text-ellipsis ml-[3rem] text-left'>
              2024年7月xx日，我们的实验室开发阶段将告一段落，并立即进入开发的下一阶段。在实验室阶段，我们完美地达到了预期的结果，为我们的研发团队喝彩！
            </p>
          </div>
          <div className='flex mb-[1rem]'>
            <img src="news1.png" className='w-[103px] h-[103px]' />
            <p className='max-w-xs overflow-hidden whitespace-nowrap text-ellipsis ml-[3rem] text-left'>2024年7月xx日，我们的实验室开发阶段将告一段落，并立即进入开发的下一阶段。在实验室阶段，我们完美地达到了预期的结果，为我们的研发团队喝彩！</p>
          </div>
          <div className='flex'>
            <img src="news1.png" className='w-[103px] h-[103px]' />
            <p className='max-w-xs overflow-hidden whitespace-nowrap text-ellipsis ml-[3rem] text-left'>2024年7月xx日，我们的实验室开发阶段将告一段落，并立即进入开发的下一阶段。在实验室阶段，我们完美地达到了预期的结果，为我们的研发团队喝彩！</p>
          </div>
        </div>
        <div className='w-[516px] h-[441px] bg-[#F7F7F7] mt-[4rem] ml-[45px] px-[3rem] py-[4rem]'>
          <div className='w-[427px] h-[113px] bg-[#5F5F5F]'></div>
          <div className='flex mt-[3rem] mb-[3rem]'>
            <div className='w-[42px] h-[42px] bg-[#5F5F5F] ml-[13px]'></div>
            <div className='w-[42px] h-[42px] bg-[#5F5F5F] ml-[13px]'></div>
            <div className='w-[42px] h-[42px] bg-[#5F5F5F] ml-[13px]'></div>
          </div>
          <p className='text-left'>2024年7月xx日，我们的实验室开发阶段将告一段落，并立即进入开发的下一阶段。在实验室阶段，我们完美地达到了预期的结果，为我们的研发团队喝彩！</p>
        </div>
      </div> */}
    </div>

      <Footer />
    </div>


  )
}

export default news
