import React from 'react'
import Header from '../widgets/Header'
import Footer from '../widgets/Footer'
function contact() {
  return (
    <div className="" >
      <Header />
      {/* <section className='bg-[url(contact.png)] bg-no-repeat bg-center bg-cover h-[732px]  flex items-center justify-center mt-[6.5rem]'>
          <div className="text-white">
          <h1 className='font-noto font-regular text-white text-[4.98rem] '>联系我们</h1>
          <p className="font-noto font-regular mt-[25px] text-[2.6rem]">Contact us</p>
          </div>
      </section> */}
      <div className="relative w-full h-[732px] overflow-hidden mt-[6.5rem] ">
        <img className='w-full object-cover h-full' src="https://www.freeimg.cn/i/2024/07/17/66976bf10b1a4.png">
        </img>
        {/* <img className='w-full object-cover h-full' src="contact.png">
        </img> */}
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center ">
          <div className="text-white  font-noto-sans-sc leading-none">
            <h1 className='font-noto font-regular tracking-[0.14rem] text-white text-[3.75rem]'>联系我们</h1>
            <p className="font-noto font-regular tracking-[0.14rem]  mt-[40px] text-[1.84rem] ">Contact us</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center">
        <div className='flex h-[90.812rem] py-[117px]'>
          <div className="h-[42.06rem] w-[733px] ">
            <div className='flex'>
              <div className="text-left max-w-md mx-[0.812rem] mt-10">
                <h1 className="text-[18px]  mb-4 font-noto font-light" >姓 名</h1>
                <input type="text" className="bg-[#F2F2F2] w-[349px] px-3 py-2 border border-gray-300 shadow-sm focus:outline-none  focus:border-indigo-500" />
              </div>
              <div className="text-left max-w-md mx-[0.812rem] mt-10">
                <h1 className="text-[18px]  mb-4 font-noto font-light">称 呼</h1>
                <input type="text" className="bg-[#F2F2F2] w-[349px] px-3 py-2 border border-gray-300 shadow-sm focus:outline-none  focus:border-indigo-500" />
              </div>
            </div>

            <div className='flex'>
              <div className="text-left max-w-md mx-[0.812rem] mt-10">
                <h1 className="text-[18px]  mb-4 font-noto font-light">邮 箱</h1>
                <input type="text" className="bg-[#F2F2F2] w-[349px] px-3 py-2 border border-gray-300 shadow-sm focus:outline-none  focus:border-indigo-500" />
              </div>
              <div className="text-left max-w-md mx-[0.812rem] mt-10">
                <h1 className="text-[18px]  mb-4 font-noto font-light">联 系 电 话</h1>
                <input type="text" className="bg-[#F2F2F2] w-[349px] px-3 py-2 border border-gray-300 shadow-sm focus:outline-none  focus:border-indigo-500" />
              </div>
            </div>
            <div className="text-left mx-[0.812rem] mt-10">
              <h1 className="text-[18px]  mb-4 font-noto font-light">留 言</h1>
              <input type="text" className="bg-[#F2F2F2]  w-[733px] h-[16.68rem] px-3 py-2 border border-gray-300 shadow-sm focus:outline-none  focus:border-indigo-500" />
            </div>
            {/* <button className="SourceHanSansCN mt-[3.96rem] mx-[0.812rem] flex items-center tracking-[0.4rem] bg-blue-500 text-[1rem] text-white rounded-[0.748rem] full px-4 py-2  w-[6rem] h-[2rem]">
              <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="1.6rem" height="1.6rem" viewBox="0 0 10.022 9.703">
                <path id="路径_4830" data-name="路径 4830" d="M2679.111,754.718h8.017v-2.506h1v3.007a.5.5,0,0,1-.5.5h-9.019a.5.5,0,0,1-.5-.5v-3.007h1Zm6.1-6.013-1.98-1.979.709-.709,3.189,3.189-3.189,3.189-.709-.709,1.98-1.979h-5.6v-1Zm0,0" transform="translate(-2678.108 -746.017)" fill="#fff" />
              </svg>
              <span className='ml-[0.6rem] text-[0.6rem]'>发 送</span>
            </button> */}
            <button class="SourceHanSansCN mt-[3.96rem] mx-[0.812rem] flex items-center tracking-[0.4rem] bg-blue-500 text-[1rem] text-white rounded-[0.748rem] px-4 py-2 w-[6.9rem] h-[2rem]">
              <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" viewBox="0 0 10.022 9.703">
                <path id="路径_4830" data-name="路径 4830" d="M2679.111,754.718h8.017v-2.506h1v3.007a.5.5,0,0,1-.5.5h-9.019a.5.5,0,0,1-.5-.5v-3.007h1Zm6.1-6.013-1.98-1.979.709-.709,3.189,3.189-3.189,3.189-.709-.709,1.98-1.979h-5.6v-1Zm0,0" transform="translate(-2678.108 -746.017)" fill="#fff" />
              </svg>
              <span class='ml-[0.6rem] text-[1rem] '>发送</span>
            </button>

          </div>
          <div className="h-[42.06rem] w-[22.812rem] pt-20 ml-[77px]">
            <nav className='text-left font-noto font-regular'>
              <p className='flex items-center  pb-[0.375rem] text-[1.75rem] whitespace-nowrap'>杭州慕皓新能源技术有限公司</p>
              <p className='flex items-center  pb-[2.375rem] text-[0.875rem] whitespace-nowrap' >Hangzhou MUHAWK Clean Energy Technology Co.,Ltd</p>
              <p className='flex items-center  pb-[0.375rem] text-[1.062rem] whitespace-nowrap'>地址 :  浙江省杭州市上城区下车路10号2564</p>
              <p className='flex items-center  pb-[14.25rem] text-[1.062rem] whitespace-nowrap'>邮编 : 310000</p>
              <p className='flex items-center  text-[2.375rem] pb-[0.187rem] whitespace-nowrap'>联系我们</p>
              <p className='flex items-center  text-[0.562rem] h-[0.492rem] pb-[0.57rem] whitespace-nowrap'>CONTACT</p>
              <p className='flex items-center  text-[0.562rem] h-[0.492rem] pb-[3.375rem] whitespace-nowrap'>INFORMASTION</p>
              <p className='flex items-center  text-[2.937rem] h-[4.75rem] pb-[0.375rem] whitespace-nowrap font-noto font-bold '>0571-86088069</p>
              <p className='flex items-center  text-[1.5rem] h-[1.6rem] whitespace-nowrap'>Mail:  jason.xue@mu-hawk.com</p>
            </nav>
          </div>

        </div>
      </div>
      <div className='w-full'>
        {/* <img className='w-full object-cover' src="map.png"></img> */}
        <img className='w-full object-cover' src="https://www.freeimg.cn/i/2024/07/17/66976b6b867ae.png"></img>
      </div>
      <Footer />
    </div>
  )
}

export default contact
