import './App.css'
import Index from './pages'
import { Routes, Route } from 'react-router-dom';
import AppRoutes from './router.jsx';

function App() {
  return (
    <div>
    {/* <Index/> */}
    <AppRoutes />
    </div>
);
}

export default App
