// src/routes.jsx
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/index';
import News from './pages/news';
import TeamInfo from './pages/teaminfo';
import Product from './pages/product';
import Contact from './pages/contact';



const AppRoutes = () => {
  return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/index" element={<Home />} />
        <Route path="/news" element={<News />} />
        <Route path="/teaminfo" element={<TeamInfo />} />
        <Route path="/product" element={<Product />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
  );
};

export default AppRoutes;