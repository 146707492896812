import React, { useState, useEffect } from 'react';
import { createContext, useContext } from 'react';
import { useCallback } from 'react';
// import { HoverContext } from '../context/HoverContext';
import Header from '../widgets/Header'
import Footer from '../widgets/Footer'


const HoverContext = createContext();



const useTooltip = (id, isSpecial) => {
  const { isNonSpecialHovered, handleMouseEnter: contextHandleMouseEnter, handleMouseLeave: contextHandleMouseLeave } = useContext(HoverContext);
  const [isHovered, setIsHovered] = useState(false);
  const [currentHovered, setCurrentHovered] = useState(null);
  const [isSpecialVisible, setIsSpecialVisible] = useState(false);

  const handleMouseEnter = useCallback(() => {
    setIsHovered(true);
    setCurrentHovered(id);
    contextHandleMouseEnter(id, isSpecial);
  }, [id, isSpecial, contextHandleMouseEnter]);

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false);
    setCurrentHovered(null);
    contextHandleMouseLeave();
  }, [contextHandleMouseLeave]);

  useEffect(() => {
    let timer;
    if (isSpecial && !isNonSpecialHovered) {
      // 当鼠标离开非指定元素并且isSpecial为true时，延迟2秒显示提示
      timer = setTimeout(() => {
        setIsSpecialVisible(true);
      }, 2000); // 延迟2秒
    } else {
      // 鼠标覆盖非指定元素时立即隐藏提示
      setIsSpecialVisible(false);
      if (timer) clearTimeout(timer); // 如果已经设置了定时器，就清除它
    }

    // 组件卸载时清除定时器
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [isSpecial, isNonSpecialHovered]);

  return {
    isHovered,
    currentHovered,
    handleMouseEnter,
    handleMouseLeave,
    isSpecialVisible,
  };
};


const ImageWithTooltip = ({ id, src, alt, text, width, top, left, isSpecial }) => {
  const {
    isHovered,
    currentHovered,
    handleMouseEnter,
    handleMouseLeave,
    isSpecialVisible,
  } = useTooltip(id, isSpecial);

  const tooltipTop = `calc(${top}rem - 6rem)`;
  const tooltipLeft = `calc(${left}rem + (${width}rem - 18rem) / 2)`;

  return (
    <div
      className=""
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <img
        className={`w-[${width}rem] absolute top-[${top}rem] left-[${left}rem]`}
        src={src}
        alt={alt}
      />
      {(isHovered && currentHovered === id && !isSpecial && id != "L41") && (
       <div className="absolute p-2 text-[2rem] opacity-[80%]" style={{ top: tooltipTop, left: tooltipLeft }}>
       <div className="relative flex items-center justify-center">
         <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="274.086" height="92.172" viewBox="0 0 274.086 92.172">
           <defs>
             <linearGradient id="linear-gradient" x1="1.022" y1="0.721" x2="-0.029" y2="0.069" gradientUnits="objectBoundingBox">
               <stop offset="0" stop-color="#036eb7" />
               <stop offset="1" stop-color="#389ee0" />
             </linearGradient>
           </defs>
           <path id="路径_4641" data-name="路径 4641" d="M1611.29,1857.984H1381.341a22.069,22.069,0,0,0-22.069,22.068v29.043a22.069,22.069,0,0,0,22.069,22.069h95.49l19.484,18.992,19.484-18.992h95.491a22.069,22.069,0,0,0,22.068-22.069v-29.043A22.068,22.068,0,0,0,1611.29,1857.984Z" transform="translate(-1359.272 -1857.984)" opacity="0.8" fill="url(#linear-gradient)" />
         </svg>
         <p className='absolute top-[19px] left-[55px] font-noto font-regular text-[37.7px] text-white leading-none'>{text}</p>
       </div>
     </div>
      )}
      {(isHovered && currentHovered === id && !isSpecial && id == "L41") && (
        <div className="absolute p-2 text-[2rem] opacity-[80%]" style={{ top: tooltipTop, left: tooltipLeft }}>
          <div className="relative flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="45.534" height="34.476" viewBox="0 0 45.534 34.476" className='absolute top-[19.8px] left-[24.5px] mr-[10px]'>
              <g id="组_1518" data-name="组 1518" transform="translate(-970.701 -1862.922)">
                <path id="路径_4643" data-name="路径 4643" d="M1007.413,1865.361a6.381,6.381,0,0,1,6.383,6.363V1888.6a6.381,6.381,0,0,1-6.383,6.363,6.288,6.288,0,0,1-4.466-1.869l-7.755-7.756-1.725,1.725,7.755,7.755a8.711,8.711,0,0,0,6.191,2.584,8.823,8.823,0,0,0,8.822-8.8v-16.872a8.823,8.823,0,0,0-8.822-8.8h0a8.706,8.706,0,0,0-6.19,2.584l-12.93,12.929-1.725,1.725,1.725,1.725,1.725-1.725,12.929-12.929a6.285,6.285,0,0,1,4.466-1.87m0,24.72a1.486,1.486,0,0,0,1.505-1.485v-16.872a1.485,1.485,0,0,0-1.505-1.484,1.4,1.4,0,0,0-1.016.44l-9.481,9.48,9.481,9.48a1.4,1.4,0,0,0,1.016.441m-7.047-9.921,6.113-6.113v12.226l-6.113-6.113Z" fill="#fff" />
                <path id="路径_4644" data-name="路径 4644" d="M979.523,1894.959a6.381,6.381,0,0,1-6.383-6.363v-16.872a6.381,6.381,0,0,1,6.383-6.363,6.285,6.285,0,0,1,4.466,1.87l7.755,7.755,1.725-1.724-7.755-7.756a8.711,8.711,0,0,0-6.191-2.584,8.823,8.823,0,0,0-8.822,8.8V1888.6a8.823,8.823,0,0,0,8.822,8.8h0a8.71,8.71,0,0,0,6.19-2.583l12.93-12.93,1.725-1.725-1.725-1.724-1.725,1.724-12.929,12.93a6.288,6.288,0,0,1-4.466,1.869m0-24.719a1.485,1.485,0,0,0-1.505,1.484V1888.6a1.486,1.486,0,0,0,1.505,1.485,1.4,1.4,0,0,0,1.016-.441l9.481-9.48-9.481-9.48a1.4,1.4,0,0,0-1.016-.44m7.047,9.92-6.113,6.113v-12.226l6.113,6.113Z" fill="#fff" />
              </g>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="274.086" height="92.172" viewBox="0 0 274.086 92.172">
              <defs>
                <linearGradient id="linear-gradient" x1="1.022" y1="0.721" x2="-0.029" y2="0.069" gradientUnits="objectBoundingBox">
                  <stop offset="0" stop-color="#036eb7" />
                  <stop offset="1" stop-color="#389ee0" />
                </linearGradient>
              </defs>
              <path id="路径_4641" data-name="路径 4641" d="M1611.29,1857.984H1381.341a22.069,22.069,0,0,0-22.069,22.068v29.043a22.069,22.069,0,0,0,22.069,22.069h95.49l19.484,18.992,19.484-18.992h95.491a22.069,22.069,0,0,0,22.068-22.069v-29.043A22.068,22.068,0,0,0,1611.29,1857.984Z" transform="translate(-1359.272 -1857.984)" opacity="0.8" fill="url(#linear-gradient)" />
            </svg>
            <p className='absolute top-[19px] left-[80.7px] font-noto font-regular text-[37.7px] text-white leading-none'>{text}</p>
          </div>
        </div>
      )}
       {(isHovered && currentHovered === id && isSpecial) && (
        <div className="absolute p-2 text-[2rem] opacity-[80%]" style={{ top: tooltipTop, left: tooltipLeft }}>
          <div className="relative flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="45.534" height="34.476" viewBox="0 0 45.534 34.476" className='absolute top-[19.8px] left-[24.5px] mr-[10px]'>
              <g id="组_1518" data-name="组 1518" transform="translate(-970.701 -1862.922)">
                <path id="路径_4643" data-name="路径 4643" d="M1007.413,1865.361a6.381,6.381,0,0,1,6.383,6.363V1888.6a6.381,6.381,0,0,1-6.383,6.363,6.288,6.288,0,0,1-4.466-1.869l-7.755-7.756-1.725,1.725,7.755,7.755a8.711,8.711,0,0,0,6.191,2.584,8.823,8.823,0,0,0,8.822-8.8v-16.872a8.823,8.823,0,0,0-8.822-8.8h0a8.706,8.706,0,0,0-6.19,2.584l-12.93,12.929-1.725,1.725,1.725,1.725,1.725-1.725,12.929-12.929a6.285,6.285,0,0,1,4.466-1.87m0,24.72a1.486,1.486,0,0,0,1.505-1.485v-16.872a1.485,1.485,0,0,0-1.505-1.484,1.4,1.4,0,0,0-1.016.44l-9.481,9.48,9.481,9.48a1.4,1.4,0,0,0,1.016.441m-7.047-9.921,6.113-6.113v12.226l-6.113-6.113Z" fill="#fff" />
                <path id="路径_4644" data-name="路径 4644" d="M979.523,1894.959a6.381,6.381,0,0,1-6.383-6.363v-16.872a6.381,6.381,0,0,1,6.383-6.363,6.285,6.285,0,0,1,4.466,1.87l7.755,7.755,1.725-1.724-7.755-7.756a8.711,8.711,0,0,0-6.191-2.584,8.823,8.823,0,0,0-8.822,8.8V1888.6a8.823,8.823,0,0,0,8.822,8.8h0a8.71,8.71,0,0,0,6.19-2.583l12.93-12.93,1.725-1.725-1.725-1.724-1.725,1.724-12.929,12.93a6.288,6.288,0,0,1-4.466,1.869m0-24.719a1.485,1.485,0,0,0-1.505,1.484V1888.6a1.486,1.486,0,0,0,1.505,1.485,1.4,1.4,0,0,0,1.016-.441l9.481-9.48-9.481-9.48a1.4,1.4,0,0,0-1.016-.44m7.047,9.92-6.113,6.113v-12.226l6.113,6.113Z" fill="#fff" />
              </g>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="274.086" height="92.172" viewBox="0 0 274.086 92.172">
              <defs>
                <linearGradient id="linear-gradient" x1="1.022" y1="0.721" x2="-0.029" y2="0.069" gradientUnits="objectBoundingBox">
                  <stop offset="0" stop-color="#036eb7" />
                  <stop offset="1" stop-color="#389ee0" />
                </linearGradient>
              </defs>
              <path id="路径_4641" data-name="路径 4641" d="M1611.29,1857.984H1381.341a22.069,22.069,0,0,0-22.069,22.068v29.043a22.069,22.069,0,0,0,22.069,22.069h95.49l19.484,18.992,19.484-18.992h95.491a22.069,22.069,0,0,0,22.068-22.069v-29.043A22.068,22.068,0,0,0,1611.29,1857.984Z" transform="translate(-1359.272 -1857.984)" opacity="0.8" fill="url(#linear-gradient)" />
            </svg>
            <p className='absolute top-[19px] left-[80.7px] font-noto font-regular text-[37.7px] text-white leading-none'>{text}</p>
          </div>
        </div>
      )}
      {isSpecialVisible && (
      <div className="absolute p-2 text-[2rem] opacity-[80%]" style={{ top: tooltipTop, left: tooltipLeft }}>
      <div className="relative flex items-center justify-center">
        <svg xmlns="http://www.w3.org/2000/svg" width="45.534" height="34.476" viewBox="0 0 45.534 34.476" className='absolute top-[19.8px] left-[24.5px] mr-[10px]'>
          <g id="组_1518" data-name="组 1518" transform="translate(-970.701 -1862.922)">
            <path id="路径_4643" data-name="路径 4643" d="M1007.413,1865.361a6.381,6.381,0,0,1,6.383,6.363V1888.6a6.381,6.381,0,0,1-6.383,6.363,6.288,6.288,0,0,1-4.466-1.869l-7.755-7.756-1.725,1.725,7.755,7.755a8.711,8.711,0,0,0,6.191,2.584,8.823,8.823,0,0,0,8.822-8.8v-16.872a8.823,8.823,0,0,0-8.822-8.8h0a8.706,8.706,0,0,0-6.19,2.584l-12.93,12.929-1.725,1.725,1.725,1.725,1.725-1.725,12.929-12.929a6.285,6.285,0,0,1,4.466-1.87m0,24.72a1.486,1.486,0,0,0,1.505-1.485v-16.872a1.485,1.485,0,0,0-1.505-1.484,1.4,1.4,0,0,0-1.016.44l-9.481,9.48,9.481,9.48a1.4,1.4,0,0,0,1.016.441m-7.047-9.921,6.113-6.113v12.226l-6.113-6.113Z" fill="#fff" />
            <path id="路径_4644" data-name="路径 4644" d="M979.523,1894.959a6.381,6.381,0,0,1-6.383-6.363v-16.872a6.381,6.381,0,0,1,6.383-6.363,6.285,6.285,0,0,1,4.466,1.87l7.755,7.755,1.725-1.724-7.755-7.756a8.711,8.711,0,0,0-6.191-2.584,8.823,8.823,0,0,0-8.822,8.8V1888.6a8.823,8.823,0,0,0,8.822,8.8h0a8.71,8.71,0,0,0,6.19-2.583l12.93-12.93,1.725-1.725-1.725-1.724-1.725,1.724-12.929,12.93a6.288,6.288,0,0,1-4.466,1.869m0-24.719a1.485,1.485,0,0,0-1.505,1.484V1888.6a1.486,1.486,0,0,0,1.505,1.485,1.4,1.4,0,0,0,1.016-.441l9.481-9.48-9.481-9.48a1.4,1.4,0,0,0-1.016-.44m7.047,9.92-6.113,6.113v-12.226l6.113,6.113Z" fill="#fff" />
          </g>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="274.086" height="92.172" viewBox="0 0 274.086 92.172">
          <defs>
            <linearGradient id="linear-gradient" x1="1.022" y1="0.721" x2="-0.029" y2="0.069" gradientUnits="objectBoundingBox">
              <stop offset="0" stop-color="#036eb7" />
              <stop offset="1" stop-color="#389ee0" />
            </linearGradient>
          </defs>
          <path id="路径_4641" data-name="路径 4641" d="M1611.29,1857.984H1381.341a22.069,22.069,0,0,0-22.069,22.068v29.043a22.069,22.069,0,0,0,22.069,22.069h95.49l19.484,18.992,19.484-18.992h95.491a22.069,22.069,0,0,0,22.068-22.069v-29.043A22.068,22.068,0,0,0,1611.29,1857.984Z" transform="translate(-1359.272 -1857.984)" opacity="0.8" fill="url(#linear-gradient)" />
        </svg>
        <p className='absolute top-[19px] left-[80.7px] font-noto font-regular text-[37.7px] text-white leading-none'>{text}</p>
      </div>
    </div>
      )}
    </div>
  );
};

const HoverProvider = ({ children }) => {
  // 用于跟踪是否悬停在非特殊元素上
  const [isNonSpecialHovered, setIsNonSpecialHovered] = useState(false);
  // 用于记录当前悬停的非特殊元素的 ID
  const [hoveredId, setHoveredId] = useState(null);

  // 处理鼠标进入事件
  const handleMouseEnter = (id, isSpecial) => {
    if (!isSpecial) {
      setIsNonSpecialHovered(true);
      setHoveredId(id);
    }
  };

  // 处理鼠标离开事件
  const handleMouseLeave = () => {
    setIsNonSpecialHovered(false);
    setHoveredId(null);
  };

  // 将状态和事件处理函数通过上下文提供给子组件
  return (
    <HoverContext.Provider value={{
      isNonSpecialHovered,
      hoveredId,
      handleMouseEnter,
      handleMouseLeave
    }}>
      {children}
    </HoverContext.Provider>
  );
};

function product() {
  return (
    <div className='font-noto-sans-sc'>
      <Header />

      <div className="relative w-full h-[732px] overflow-hidden mt-[6.5rem] ">
        <video
          className="absolute top-0 left-0 w-full h-full object-cover"
          autoPlay
          loop
          muted
          playsInline
        >
          <source src="product.mp4" type="video/mp4" />
          Your browser does not support the video tag.s
        </video>
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center ">
          <div className="text-white  font-noto-sans-sc leading-none">
            <h1 className='font-noto font-regular tracking-[0.14rem] text-white text-[3.75rem]  '>产品与技术</h1>
            <p className="font-noto font-regular tracking-[0.14rem]  mt-[40px] text-[1.84rem] ">Products and Technology</p>
          </div>
        </div>
      </div>
      <div className="flex justify-center h-[33.625rem] pt-[175px]  font-noto font-regular max-w-full mx-auto">
        <div className='text-left max-w-[1298px]'>
          <p className='text-[3.29rem] mb-[1.375rem] font-noto font-bold '>产品介绍</p>
          <p className='text-[1.5rem] font-noto font-thin'>天然气、生物燃料和其他碳氢化合物燃料的可用于氢的生产。作为第四态物质，等离子体技术可
            以与风，光等绿色能源结合用于氢气的生产。等离子体条件（高温和高电离度）可用于加速热力
            学上可行的化学反应，而无需催化剂或提供吸热式改质所需的能量。
            <br />
            <br />
            等离子体可带来许多优势：
            <br />
            经济上具有吸引力的小规模的氢生成、高转化率、无须催化剂敏感性和无CO₂生成问题、紧凑和轻量化（由于功率密度高）、最低成本、快速响应时间（几分之一秒）
          </p>
        </div>
      </div>
      <div className='bg-white h-[12rem] w-full'></div>
      {/* <HoverProvider>
        <div className='flex justify-center items-center h-screen'>
          <div className="relative w-[1298px] mx-auto h-[57rem] mt-[12.5rem] pb-[37rem]">
            <img className="w-[1rem] absolute top-[10.5rem] left-[29.6rem]" src="YX.png" alt="天然气图片1" />
            <img className="w-[1rem] absolute top-[18.7rem] left-[42.44rem]" src="YX.png" alt="天然气图片1" />
            <img className="w-[1rem] absolute top-[27.5rem] left-[57rem]" src="YX.png" alt="天然气图片1" />
            <img className="w-[1rem] absolute top-[20rem] left-[56rem]" src="ZX.png" alt="天然气图片1" />
            <img className="w-[1rem] absolute top-[27.5rem] left-[44.5rem]" src="ZX.png" alt="天然气图片1" />
            <img className="w-[1rem] absolute top-[37rem] left-[31rem]" src="ZX.png" alt="天然气图片1" />
            <img className="w-[33rem] absolute top-[0rem] left-[0rem]" src="L1.png" alt="天然气图片1" />
            <img className="w-[1rem] absolute top-[20rem] left-[70rem]" src="ZS.png" alt="天然气图片1" />
            <img className="w-[11.4rem] absolute top-[3rem] left-[18rem]" src="chuan.png" alt="天然气图片1" />
            <img className="w-[4rem] absolute top-[11rem] left-[7rem]" src="yun.png" alt="天然气图片1" />
            <img className="w-[20.6rem] absolute top-[8.76rem] left-[26.8rem]" src="L21.png" alt="天然气图片2" />
            <img className="w-[20.6rem] absolute top-[16rem] left-[15rem]" src="L31.png" alt="天然气图片1" />
            <ImageWithTooltip
              id="L22"
              src="L22.png"
              alt="天然气图片2"
              text="储能单元"
              width="20.6"
              top="8.76"
              left="53"
              isSpecial={false}
            />
            <ImageWithTooltip
              id="L32"
              src="L32.png"
              alt="天然气图片2"
              text="慕皓制氢"
              width="20.6"
              top="17"
              left="41"
              isSpecial={true}
            />
            <ImageWithTooltip
              id="L33"
              src="L33.png"
              alt="天然气图片2"
              text="绿电获取"
              width="20.6"
              top="14.5"
              left="65"
              isSpecial={false}
            />
            <ImageWithTooltip
              id="L41"
              src="L41.png"
              alt="天然气图片1"
              text="慕皓提纯"
              width="20.6"
              top="24"
              left="27"
              isSpecial={false}
            />
            <ImageWithTooltip
              id="L42"
              src="L42.png"
              alt="天然气图片1"
              text="高纯炭黑"
              width="20.6"
              top="25.5"
              left="53"
              isSpecial={false}
            />
            <ImageWithTooltip
              id="L51"
              src="L51.png"
              alt="天然气图片2"
              text="洁净能源"
              width="20.6"
              top="35"
              left="14"
              isSpecial={false}

            />
          </div>
        </div>
      </HoverProvider> */}
      {/* <HoverProvider>*/
      <div className='flex justify-center items-center h-screen'>
        <div className="relative w-[1298px] mx-auto h-[57rem] pt-[5rem] pb-[37rem]">
          <img className="w-[1rem] absolute top-[10.5rem] left-[29.6rem]" src="YX.png" alt="天然气图片1" />
          <img className="w-[1rem] absolute top-[18.7rem] left-[42.44rem]" src="YX.png" alt="天然气图片1" />
          <img className="w-[1rem] absolute top-[27.5rem] left-[57rem]" src="YX.png" alt="天然气图片1" />
          <img className="w-[1rem] absolute top-[20rem] left-[56rem]" src="ZX.png" alt="天然气图片1" />
          <img className="w-[1rem] absolute top-[18rem] left-[31rem]" src="ZX.png" alt="天然气图片1" />
          <img className="w-[1rem] absolute top-[27.5rem] left-[44.5rem]" src="ZX.png" alt="天然气图片1" />
          <img className="w-[1rem] absolute top-[36.4rem] left-[29.8rem]" src="ZX.png" alt="天然气图片1" />
          <img className="w-[1rem] absolute top-[20rem] left-[70rem]" src="ZS.png" alt="天然气图片1" />
          <img className="w-[33rem] absolute top-[0rem] left-[0rem]" src="L1.png" alt="天然气图片1" />
          <img className="w-[11.4rem] absolute top-[3rem] left-[18rem]" src="chuan.png" alt="天然气图片1" />
          <img className="w-[4rem] absolute top-[11rem] left-[7rem]" src="yun.png" alt="天然气图片1" />
          <img className="w-[20.6rem] absolute top-[8.76rem] left-[26.8rem]" src="L21.png" alt="天然气图片2" />
          <img className="w-[20.6rem] absolute top-[8.76rem] left-[53rem]" src="L22.png" alt="天然气图片2" />
          <img className="w-[20.6rem] absolute top-[16rem] left-[15rem]" src="L31.png" alt="天然气图片1" />
          <img className="w-[20.6rem] absolute top-[17rem] left-[41rem]" src="L32.png" alt="天然气图片2" />
          <img className="w-[20.6rem] absolute top-[14.5rem] left-[65rem]" src="L33.png" alt="天然气图片2" />
          <img className="w-[20.6rem] absolute top-[24.5rem] left-[27.4rem]" src="L41.png" alt="天然气图片1" />
          <img className="w-[20.6rem] absolute top-[25.5rem] left-[53rem]" src="L42.png" alt="天然气图片1" />
          <img className="w-[20.6rem] absolute top-[35rem] left-[14rem]" src="L51.png" alt="天然气图片2" />
          <img className="w-[20.6rem] absolute top-[8.76rem] left-[53rem]" src="L22.png" alt="天然气图片2" />
        </div>
      </div>
     /* </HoverProvider> */}
      <div className="grid place-items-center mt-[8rem]">
        <div className='flex w-[78rem] text-[2rem] mt-[5rem] mb-[10rem] '>
          <div className='flex w-[78rem]  px-[4rem]'>
            <div className='w-[18rem] h-[643px] bg-[#F7F7F7] rounded-[1rem] flex flex-col items-center py-[4.56rem]' >
              <svg xmlns="http://www.w3.org/2000/svg" width="6rem" height="6.8rem" viewBox="0 0 31.168 34.38">
                <g id="组_3260" data-name="组 3260" transform="translate(-833.512 -1002.711)">
                  <path id="路径_9411" data-name="路径 9411" d="M854.444,1007.354a15.085,15.085,0,0,1,8.4,20.282" fill="none" stroke="#036eb7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                  <path id="路径_9412" data-name="路径 9412" d="M842.909,1035.2a15.113,15.113,0,0,0,12.374,0" fill="none" stroke="#036eb7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                  <path id="路径_9413" data-name="路径 9413" d="M835.348,1027.635a15.084,15.084,0,0,1,8.4-20.281" fill="none" stroke="#036eb7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                  <path id="路径_9414" data-name="路径 9414" d="M862,1034.354a3.178,3.178,0,0,1-4.5,0,3.022,3.022,0,0,1-.222-.247,3.179,3.179,0,0,1,4.471-4.471c.085.07.167.144.247.223A3.179,3.179,0,0,1,862,1034.354Z" fill="none" stroke="#036eb7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                  <circle id="椭圆_88" data-name="椭圆 88" cx="3.179" cy="3.179" r="3.179" transform="translate(835.26 1028.928)" fill="none" stroke="#036eb7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                  <path id="路径_9415" data-name="路径 9415" d="M852.258,1006.711a3.176,3.176,0,1,1-.914-2.57A3.158,3.158,0,0,1,852.258,1006.711Z" fill="none" stroke="#036eb7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                  <text id="H" transform="translate(847.03 1026.723)" fill="#00a63c" font-size="16.335" font-family="SegoeUI, Segoe UI" letterSpacing="0.1em"><tspan x="-5.799" y="0">H</tspan></text>
                  <text id="_2" data-name="2" transform="translate(854.547 1026.723)" fill="#00a63c" font-size="7.753" font-family="SegoeUI, Segoe UI" letter-spacing="0.1em"><tspan x="-2.09" y="0">2</tspan></text>
                </g>
              </svg>
              <p className='mt-[1rem] text-[2rem] font-noto font-medium'>高效的<br />能量利用率 </p>
              <div className='text-left mt-[3rem] mr-[2rem] ml-[2rem] w-[12rem] text-[1.2rem] font-noto font-thin'><p>独特的等离子炬设计带来的高转化率和高热效率，这意味着该过程的能量利用率较高，继而有效降低生产成本。</p>
              </div>

            </div>
            <div className='w-[18rem] h-[643px] bg-[#F7F7F7] rounded-[1rem] ml-[2rem] flex flex-col items-center py-[4.56rem]'> <svg xmlns="http://www.w3.org/2000/svg" width="6rem" height="6.8rem" viewBox="0 0 29.417 35.744">
              <g id="组_3261" data-name="组 3261" transform="translate(-930.608 -1001.986)">
                <path id="路径_9416" data-name="路径 9416" d="M945.317,1037.047l14.208-8.2v-16.406l-14.208-8.2-14.208,8.2v16.406l14.208,8.2" fill="none" stroke="#036eb7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                <line id="直线_247" data-name="直线 247" y2="13.83" transform="translate(933.946 1013.879)" fill="none" stroke="#00a63c" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                <text id="c" transform="translate(944.627 1026.986)" fill="#00a63c" font-size="23.512" font-family="SegoeUI, Segoe UI" letter-spacing="0.1em"><tspan x="-5.43" y="0">c</tspan></text>
              </g>
            </svg>

              <p className='mt-[1rem] text-[2rem] font-noto font-medium'>产物应用<br />优势明显 </p>
              <div className='text-left mt-[3rem] mr-[2rem] ml-[2rem] w-[12rem] text-[1.2rem] font-noto font-thin'><p>可生产纳米级炭黑，同时通过工艺调节可以实现炭黑粒度分布调整，能有效匹配各个炭黑应用领域。</p>
              </div>
            </div>
            <div className='w-[18rem] h-[643px] bg-[#F7F7F7] rounded-[1rem] ml-[2rem] flex flex-col items-center py-[4.56rem]'><svg xmlns="http://www.w3.org/2000/svg" width="6rem" height="6.8rem" viewBox="0 0 33.069 30.809">
              <g id="组_3259" data-name="组 3259" transform="translate(-1024.972 -1007.761)">
                <path id="路径_9404" data-name="路径 9404" d="M1048.475,1016.864l3.635-6.292-2.329,1.351s-.911-1.593-1.276-2.22a2.849,2.849,0,0,0-4.962.012s-.835,1.439-1.674,2.9l1.692,2.929-2.353,1.321Z" fill="none" stroke="#036eb7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                <path id="路径_9405" data-name="路径 9405" d="M1037.444,1008.261a2.572,2.572,0,0,0-2.181,1.273c-.787,1.288-3.779,6.552-3.779,6.552l6.256,3.614s4.7-8.148,5.553-9.62a3.581,3.581,0,0,1,3.131-1.819Z" fill="none" stroke="#036eb7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                <path id="路径_9406" data-name="路径 9406" d="M1044.224,1031.776l3.632,6.294-.006-2.692s1.835.007,2.56.005a2.85,2.85,0,0,0,2.471-4.3s-.828-1.443-1.673-2.9h-3.383l.032-2.7-3.633,6.293Z" fill="none" stroke="#00a63c" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                <path id="路径_9407" data-name="路径 9407" d="M1057.19,1026.525a2.573,2.573,0,0,0-.012-2.526c-.722-1.326-3.785-6.549-3.785-6.549l-6.258,3.611s4.707,8.144,5.555,9.62a3.579,3.579,0,0,1,.009,3.621l4.49-7.777Z" fill="none" stroke="#00a63c" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                <path id="路径_9408" data-name="路径 9408" d="M1032.739,1020.081l-7.267,0,2.335,1.341s-.924,1.585-1.285,2.215a2.85,2.85,0,0,0,2.492,4.291s1.664,0,3.347,0l1.691-2.93,2.321,1.377-3.634-6.293Z" fill="none" stroke="#036eb7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                <path id="路径_9409" data-name="路径 9409" d="M1030.8,1033.935a2.573,2.573,0,0,0,2.193,1.252c1.509.038,7.564,0,7.564,0l0-7.224s-9.406,0-11.108,0a3.58,3.58,0,0,1-3.141-1.8l4.49,7.777Z" fill="none" stroke="#036eb7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                <path id="路径_9410" data-name="路径 9410" d="M1037.113,1017.359" fill="none" stroke="#036eb7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
              </g>
            </svg>

              <p className='mt-[1rem] text-[2rem] font-noto font-medium'>工艺过程 <br /> 绿色环保 </p>
              <div className='text-left mt-[3rem] mr-[2rem] ml-[2rem] w-[12rem] text-[1.2rem] font-noto font-thin'><p>无CO₂排放的工艺结合绿色能源，可做到真正意义上的绿氢和绿色炭黑制备；与传统的炭黑生产工艺相比，本工艺现场洁净、对环境友好，符合绿色化学的原则。</p>
              </div></div>
            <div className='w-[18rem] h-[643px] bg-[#F7F7F7] rounded-[1rem] ml-[2rem] flex flex-col items-center py-[4.56rem]'>
              <svg xmlns="http://www.w3.org/2000/svg" width="6rem" height="6.8rem" viewBox="0 0 37.319 31.942">
                <g id="组_3263" data-name="组 3263" transform="translate(-1120.653 -1006.335)">
                  <line id="直线_248" data-name="直线 248" x2="22.155" transform="translate(1121.153 1037.777)" fill="none" stroke="#036eb7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                  <g id="组_3262" data-name="组 3262">
                    <path id="路径_9417" data-name="路径 9417" d="M1123.384,1037.777v-27.932a3.01,3.01,0,0,1,3.01-3.01h11.674a3.01,3.01,0,0,1,3.009,3.01v27.932" fill="none" stroke="#036eb7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                    <line id="直线_249" data-name="直线 249" x2="10.325" transform="translate(1126.861 1013.307)" fill="none" stroke="#036eb7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                    <path id="路径_9418" data-name="路径 9418" d="M1141.077,1016.576h1.245a3.632,3.632,0,0,1,3.632,3.632v8.522a3.632,3.632,0,0,0,3.632,3.632h0a3.632,3.632,0,0,0,3.632-3.632v-13.394" fill="none" stroke="#036eb7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                    <path id="矩形_611" data-name="矩形 611" d="M0,0H8.509a0,0,0,0,1,0,0V2.866A2.184,2.184,0,0,1,6.325,5.05H2.184A2.184,2.184,0,0,1,0,2.866V0A0,0,0,0,1,0,0Z" transform="translate(1148.963 1010.286)" fill="none" stroke="#036eb7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                    <line id="直线_250" data-name="直线 250" y1="3.424" transform="translate(1151.35 1006.844)" fill="none" stroke="#036eb7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                    <line id="直线_251" data-name="直线 251" y1="3.424" transform="translate(1155.086 1006.844)" fill="none" stroke="#036eb7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                    <text id="H" transform="translate(1130.788 1028.598)" fill="#00a63c" font-size="12.331" font-family="SegoeUI, Segoe UI" letter-spacing="0.1em"><tspan x="-4.377" y="0">H</tspan></text>
                    <text id="_2" data-name="2" transform="translate(1136.462 1028.597)" fill="#00a63c" font-size="5.853" font-family="SegoeUI, Segoe UI" letter-spacing="0.1em"><tspan x="-1.578" y="0">2</tspan></text>
                  </g>
                </g>
              </svg>

              <p className='mt-[1rem] text-[2rem] font-noto font-medium'>产物应用 <br /> 优势明显 </p>
              <div className='text-left mt-[3rem] mr-[2rem] ml-[2rem] w-[12rem] text-[1.2rem] font-noto font-thin'><p>撬装设备可从千瓦级别到兆瓦级别可灵活组合，从分布式制氢到工业化制氢可任意调整，极大满足各种需求量及需求目的下的应用场合。</p>
              </div></div>
          </div>
        </div>
      </div>
      <div className="grid place-items-center">
        <div className='flex w-full max-w-screen-lg text-[2rem] mb-[26rem] '>
          <div className='flex flex-col items-center font-noto font-thin'>
            <p>天然气</p>
            <div className="relative">
              <img className="w-[22rem]" src="trq.png" alt="天然气图片1" />
              {/* <img className="w-[22rem] absolute top-[100px] left-0" src="trqyy.png" alt="天然气图片2" style={{ transform: 'scale(2) translate(0, 15%)', transformOrigin: 'center' }} /> */}

            </div>
          </div>
          <div className='flex flex-col items-center font-noto font-thin'>
            <p>等离子</p>
            <div className="relative">
              <img className="w-[11rem] h-[9.5] mt-[7rem]" src="dlz.png" alt="天然气图片1" />
            </div>
          </div>
          <div className='flex flex-col items-center ml-[6rem] w-wull font-noto font-thin'>
            <p>炭黑</p>
            <div className="relative ">
              <img className="w-[7rem] mt-[5rem] " src="th.png" alt="天然气图片1" />
              {/* <img className="w-[22rem] absolute top-[170px] left-0" src="dlzyy.png" alt="天然气图片2" style={{ transform: 'scale(6) translate(0, 15%)', transformOrigin: 'center' }} /> */}
            </div>
          </div>
          <div className='flex flex-col items-center ml-[5rem] mt-[8rem] font-noto font-thin'>
            <div className="relative">
              <img className="w-[3rem] h-[5rem]" src="+.png" alt="天然气图片1" />
            </div>
          </div>
          <div className='flex flex-col items-center font-noto font-thin'>
            <p>氢气</p>
            <div className="relative">
              <img className="w-[15rem] " src="qq.png" alt="天然气图片1" />
              {/* <img className="w-[22rem] absolute top-[160px] left-0" src="qqyy.png" alt="天然气图片2" style={{ transform: 'scale(3) translate(0, 12%)', transformOrigin: 'center' }} /> */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>


  )
}

export default product
