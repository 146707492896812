import React from 'react';
import { Link } from 'react-router-dom';
function Footer() {
  return (
    <footer className="w-full flex-wrap">
      <div className="bg-[url('/ftbg.png')] bg-no-repeat bg-center bg-cover h-[500px] flex justify-center items-center pb-[5rem]" style={{backgroundImage:`url("ftbg.png")`}}>
        <div className='max-w-[1920px] w-full px-[3rem] '>
        <div className='pt-[137px]'>
          <svg xmlns="http://www.w3.org/2000/svg" width="127px" height="39px" viewBox="0 0 48.303 15.124 ">
            <g id="组_3386" data-name="�? 3386" transform="translate(-141.795 -1709.341)">
              <g id="组_3378" data-name="�? 3378">
                <path id="路径_9614" data-name="路径 9614" d="M157.9,1710.411a2.8,2.8,0,0,1,2.8,2.791v7.4a2.8,2.8,0,0,1-2.8,2.792,2.761,2.761,0,0,1-1.959-.82l-3.4-3.4-.756.756,3.4,3.4a3.819,3.819,0,0,0,2.715,1.134,3.87,3.87,0,0,0,3.87-3.862v-7.4a3.87,3.87,0,0,0-3.87-3.861h0a3.822,3.822,0,0,0-2.715,1.133l-5.672,5.672-.757.757.757.756.757-.756,5.671-5.672a2.757,2.757,0,0,1,1.959-.82m0,10.844a.652.652,0,0,0,.661-.652v-7.4a.652.652,0,0,0-.661-.651.614.614,0,0,0-.445.193l-4.159,4.159,4.159,4.159a.615.615,0,0,0,.445.193m-3.091-4.352,2.682-2.682v5.363l-2.682-2.681Z" fill="#fff" />
                <path id="路径_9615" data-name="路径 9615" d="M145.665,1723.4a2.8,2.8,0,0,1-2.8-2.792v-7.4a2.8,2.8,0,0,1,2.8-2.791,2.757,2.757,0,0,1,1.959.82l3.4,3.4.757-.756-3.4-3.4a3.822,3.822,0,0,0-2.715-1.133,3.87,3.87,0,0,0-3.87,3.861v7.4a3.87,3.87,0,0,0,3.87,3.862h0a3.819,3.819,0,0,0,2.715-1.134l5.672-5.671.757-.757-.757-.757-.756.757-5.672,5.672a2.761,2.761,0,0,1-1.959.82m0-10.844a.652.652,0,0,0-.66.651v7.4a.652.652,0,0,0,.66.652.615.615,0,0,0,.446-.193l4.159-4.159-4.159-4.159a.615.615,0,0,0-.446-.193m3.091,4.352-2.681,2.681v-5.363l2.681,2.682Z" fill="#fff" />
              </g>
              <path id="路径_9616" data-name="路径 9616" d="M169.6,1711.756l-2.017,1.885-2.94-3.379h5.881Z" fill="#00a63c" />
              <g id="组_3379" data-name="�? 3379">
                <path id="矩形_707" data-name="矩形 707" d="M0,0H1.349a0,0,0,0,1,0,0V6.074a0,0,0,0,1,0,0h0A1.349,1.349,0,0,1,0,4.724V0A0,0,0,0,1,0,0Z" transform="translate(172.551 1710.261)" fill="#fff" />
                <rect id="矩形_708" data-name="矩形 708" width="1.349" height="6.074" transform="translate(177.276 1710.261)" fill="#fff" />
                <path id="矩形_709" data-name="矩形 709" d="M0,0H1.349a0,0,0,0,1,0,0V4.724A1.349,1.349,0,0,1,0,6.074H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" transform="translate(178.625 1714.979) rotate(90)" fill="#fff" />
              </g>
              <g id="组_3380" data-name="�? 3380">
                <rect id="矩形_710" data-name="矩形 710" width="1.349" height="6.074" transform="translate(163.558 1710.261)" fill="#fff" />
                <rect id="矩形_711" data-name="矩形 711" width="1.349" height="6.074" transform="translate(170.527 1710.261)" fill="#fff" />
                <rect id="矩形_712" data-name="矩形 712" width="1.349" height="5.323" transform="translate(163.953 1711.215) rotate(-45)" fill="#fff" />
                <rect id="矩形_713" data-name="矩形 713" width="1.349" height="5.323" transform="translate(167.716 1714.979) rotate(-135)" fill="#fff" />
              </g>
              <g id="组_3385" data-name="�? 3385">
                <g id="组_3381" data-name="�? 3381">
                  <rect id="矩形_714" data-name="矩形 714" width="1.349" height="6.074" transform="translate(163.558 1717.699)" fill="#fff" />
                  <rect id="矩形_715" data-name="矩形 715" width="1.349" height="6.074" transform="translate(168.282 1717.699)" fill="#fff" />
                  <rect id="矩形_716" data-name="矩形 716" width="1.349" height="6.074" transform="translate(169.632 1720.061) rotate(90)" fill="#fff" />
                </g>
                <g id="组_3382" data-name="�? 3382">
                  <path id="矩形_717" data-name="矩形 717" d="M0,0H1.349a0,0,0,0,1,0,0V6.074a0,0,0,0,1,0,0h0A1.349,1.349,0,0,1,0,4.724V0A0,0,0,0,1,0,0Z" transform="translate(177.055 1717.699)" fill="#fff" />
                  <rect id="矩形_718" data-name="矩形 718" width="1.349" height="6.074" transform="translate(179.417 1717.699)" fill="#fff" />
                  <path id="矩形_719" data-name="矩形 719" d="M0,0H1.349a0,0,0,0,1,0,0V4.724A1.349,1.349,0,0,1,0,6.074H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" transform="translate(181.779 1717.699)" fill="#fff" />
                  <path id="矩形_720" data-name="矩形 720" d="M0,0H0A1.349,1.349,0,0,1,1.349,1.349V4.724A1.349,1.349,0,0,1,0,6.074H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" transform="translate(183.129 1722.423) rotate(90)" fill="#fff" />
                </g>
                <g id="组_3383" data-name="�? 3383">
                  <path id="矩形_721" data-name="矩形 721" d="M1.349,0h0a0,0,0,0,1,0,0V6.074a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V1.349A1.349,1.349,0,0,1,1.349,0Z" transform="translate(170.306 1717.699)" fill="#fff" />
                  <path id="矩形_722" data-name="矩形 722" d="M0,0H0A1.349,1.349,0,0,1,1.349,1.349V6.074a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" transform="translate(175.031 1717.699)" fill="#fff" />
                  <path id="矩形_723" data-name="矩形 723" d="M1.349,0h0a0,0,0,0,1,0,0V6.074a0,0,0,0,1,0,0h0A1.349,1.349,0,0,1,0,4.724V1.349A1.349,1.349,0,0,1,1.349,0Z" transform="translate(176.38 1717.699) rotate(90)" fill="#fff" />
                  <rect id="矩形_724" data-name="矩形 724" width="1.349" height="6.074" transform="translate(176.38 1720.061) rotate(90)" fill="#fff" />
                </g>
                <g id="组_3384" data-name="�? 3384">
                  <rect id="矩形_725" data-name="矩形 725" width="1.349" height="6.074" transform="translate(183.803 1717.699)" fill="#fff" />
                  <path id="路径_9617" data-name="路径 9617" d="M188.183,1723.766h1.908l-3.03-3.03,3.037-3.037H188.19l-2.083,2.083-.954.954.954.954Z" fill="#fff" />
                </g>
              </g>
            </g>
          </svg>
        </div>

        {/* <div className='flex text-white mx-auto max-w-[1920px] w-full text-left mt-[3.1rem]'>
          <nav className=' text-left border-r-2 border-r-white w-[353px]'>
            <p className='flex items-center  text-[1.46rem] pb-[0.5rem]'>联系我们</p>
            <p className='flex items-center  text-[0.46rem] h-[0.492rem] pb-[1rem]'>CONTACT</p>
            <p className='flex items-center  text-[0.46rem] h-[0.492rem] pb-[2.7rem]'>INFORMASTION</p>
            <p className='flex items-center  text-[2.18rem] h-[1.56rem] pb-[0.54rem]'>0571-86088069</p>
            <p className='flex items-center  text-[1rem] h-[1rem]'>Mail:  jason.xue@mu-hawk.com</p>
          </nav>
          <nav className='text-left w-[374px] pl-[40px] leading-none'>
            <p className='flex items-center   pb-[0.375rem] text-[1.63rem] whitespace-nowrap'>杭州慕皓新能源技术有限公司</p>
            <p className='flex items-center   pb-[3rem] text-[0.854rem] whitespace-nowrap' >Hangzhou MUHAWK Clean Energy Technology Co.,Ltd</p>
            <p className='flex items-center   pb-[0.187rem] text-[1rem] whitespace-nowrap'>ADD :  上海市长宁区黄金城道258弄北区4-1502</p>
            <p className='flex items-center   text-[1rem]'>邮编 : 200000</p>
          </nav>
          <nav className='text-left border-r-2 border-r-white w-[292px] pl-[40px]'>
            <Link to="/index" className='items-center  block text-[0.786rem] '>主页</Link>
            <Link to="/product" className='items-center  block text-[0.786rem] pt-[1.1rem]'>产品与技术</Link>
            <Link to="/teaminfo" className='items-center  block text-[0.786rem] pt-[1.1rem]'>团队介绍</Link>
            <Link to="/news" className='items-center  block text-[0.786rem] pt-[1.1rem]'>新闻动态</Link>
            <Link to="/contact" className='items-center block text-[0.786rem] pt-[1.1rem]'>联系我们</Link>
          </nav>
        </div> */}

        <div className='flex text-white mx-auto max-w-[1920px] w-full text-left mt-[3.1rem] h-full'>
        <nav className=' text-left border-r-2 border-r-white w-[353px] leading-none'>
            <p className='flex items-center  text-[1.46rem] pb-[0.5rem]'>联系我们</p>
            <p className='flex items-center  text-[0.46rem] h-[0.492rem] pb-[1rem]'>CONTACT</p>
            <p className='flex items-center  text-[0.46rem] h-[0.492rem] pb-[2.7rem]'>US</p>
            <p className='flex items-center  text-[2.18rem] h-[1.56rem] pb-[0.54rem]'>0571-86088069</p>
            <p className='flex items-center  text-[1rem] h-[1rem]'>Mail:  jason.xue@mu-hawk.com</p>
          </nav>
          <nav className='text-left w-[374px] pl-[40px] leading-none'>
            <p className='flex items-center   pb-[0.375rem] text-[1.63rem] whitespace-nowrap'>杭州慕皓新能源技术有限公司</p>
            <p className='flex items-center   pb-[3rem] text-[0.854rem] whitespace-nowrap' >Hangzhou MUHAWK Clean Energy Technology Co.,Ltd</p>
            <p className='flex items-center   pb-[0.187rem] text-[1rem] whitespace-nowrap'>地址 :  浙江省杭州市上城区下车路10号2564</p>
            <p className='flex items-center   text-[1rem]'>邮编 : 310000</p>
          </nav>
          <nav className='text-right border-r-white pl-[40px] flex-grow'>
            <Link to="/index" className='items-center  block text-[0.786rem] '>主页</Link>
            <Link to="/product" className='items-center  block text-[0.786rem] pt-[1.1rem]'>产品与技术</Link>
            <Link to="/teaminfo" className='items-center  block text-[0.786rem] pt-[1.1rem]'>团队介绍</Link>
            <Link to="/news" className='items-center  block text-[0.786rem] pt-[1.1rem]'>新闻动态</Link>
            <Link to="/contact" className='items-center block text-[0.786rem] pt-[1.1rem]'>联系我们</Link>
          </nav>
        </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
